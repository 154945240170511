import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 8L6 13M6 13L1 8M6 13L6 1" stroke="#FF6501" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Icon;
