import React from 'react';
import classnames from 'classnames';
import type { Swiper } from 'swiper/types';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import { desktop, tablet } from '../../../../helpers/utils/screensizes';
import Slider, { SliderProps } from '../../slider';
import { Button as ButtonType } from '../../types/Button';
import Button from '../button';
import Markdown from '../markdown';

type ContentSliderProps = {
  title: string;
  slides: Slides[];
  slidesPerMobile: number;
  slidesPerTablet: number;
  slidesPerDesktop: number;
};

type Slides = {
  image: MediaItemWithMods;
  isIcon: boolean;
  contentAlignment: 'justify-start' | 'justify-center' | 'justify-end';
  headline: string;
  text: string;
  buttonList: ButtonType[];
};

const ContentSlider: React.FC<ContentSliderProps> = ({
  title,
  slides,
  slidesPerMobile,
  slidesPerTablet,
  slidesPerDesktop,
}) => {
  const [isDesktopSize] = useMediaQuery(desktop);

  const getSlidesCountPerView = (currentWidth) => {
    if (currentWidth < tablet) {
      return slidesPerMobile && slidesPerMobile > 0 ? slidesPerMobile : 1;
    }

    if (currentWidth < desktop) {
      return slidesPerTablet && slidesPerTablet > 0 ? slidesPerTablet : 3;
    }

    return slidesPerDesktop && slidesPerDesktop > 0 ? slidesPerDesktop : 4;
  };

  const updateSliderPosition = (swiper: Swiper) => {
    const timeOut = setTimeout(() => {
      const leftPosition: string = swiper?.slidesSizesGrid?.length
        ? `${Math.round(swiper?.slidesSizesGrid[0] / 3 / 2) + 3}px`
        : undefined;

      if (!leftPosition) {
        return;
      }

      if (!swiper.isBeginning && !swiper.isEnd) {
        swiper.wrapperEl.style.left = leftPosition;
      } else if (window.innerWidth < desktop) {
        swiper.wrapperEl.style.left =
          swiper.isEnd && swiper.slides.length > getSlidesCountPerView(window.innerWidth)
            ? `-${leftPosition}`
            : leftPosition;
      } else {
        swiper.wrapperEl.style.left = 'unset';
      }

      clearTimeout(timeOut);
    }, 50);
  };

  const updateSliderHeight = (swiper: Swiper) => {
    const elementPadding = 48;

    const resizeObserver = new ResizeObserver(() => {
      updateSliderPosition(swiper);
      const timeOut = setTimeout(() => {
        const slidesHeights = swiper.slides?.map(
          (slide) =>
            Array.from(slide.children[0].children).reduce(
              (current: number, child: Element) => child.getBoundingClientRect().height + current,
              0,
            ) as number,
        );
        const maxHeight = getLargestElement(slidesHeights) + elementPadding;

        swiper.slides?.forEach((slide) => {
          const firstChild = slide.childNodes[0] as HTMLElement; // Type assertion
          firstChild.style.height = `${maxHeight}px`;
        });
        clearTimeout(timeOut);
      }, 50);
    });

    if (swiper?.el && swiper?.slides?.length) {
      resizeObserver.observe(swiper.el);
    }
  };

  const getLargestElement = (arr: number[]) => {
    return arr?.reduce((largest: number, current: number) => (current > largest ? current : largest), arr[0]);
  };

  const getSlidesPerView = (slidesCount: number): number => {
    return slides.length > slidesCount ? slidesCount + 1 / 3 : slidesCount;
  };

  const sliderConfiguration: SliderProps = {
    className: classnames('content-slider-wrapper', {
      '!pt-16': isDesktopSize && slides.length > 4,
    }),
    slidesPerView: slidesPerMobile && slidesPerMobile > 0 ? getSlidesPerView(slidesPerMobile) : 1.333,
    fitToSlides: true,
    arrows: isDesktopSize && slides.length > 4,
    autoHeight: true,
    onSlideChange: updateSliderPosition,
    onNavigationNext: updateSliderPosition,
    onNavigationPrev: updateSliderPosition,
    onInit: updateSliderHeight,
    dots: !isDesktopSize,
    pagination: isDesktopSize
      ? false
      : {
          clickable: true,
          dynamicBullets: true,
          bulletActiveClass: 'swiper-pagination-bullet-active',
        },
    breakpoints: {
      [tablet]: {
        slidesPerView: slidesPerTablet && slidesPerTablet > 0 ? getSlidesPerView(slidesPerTablet) : 3.333,
      },
      [desktop]: {
        slidesPerView: slidesPerDesktop && slidesPerDesktop > 0 ? getSlidesPerView(slidesPerDesktop) : 4.333,
      },
    },
  };

  return (
    <div className="my-5 -ml-4 w-screen lg:ml-0 lg:w-full">
      {title && (
        <div
          className={classnames('mb-4 ml-4 text-lg font-bold lg:ml-0', {
            'lg:mb-0': isDesktopSize && slides.length > 4,
          })}
        >
          {title}
        </div>
      )}
      <Slider {...sliderConfiguration}>
        {slides.map(({ image, isIcon, contentAlignment, headline, text, buttonList }, index) => (
          <div
            key={`content-slider-${index}`}
            className={classnames(
              'flex h-full flex-col items-center overflow-hidden rounded-lg bg-neutral-100 px-4 py-6 shadow-md',
              contentAlignment,
            )}
          >
            {image && (
              <div
                className={classnames({
                  'w-[50px] pb-2': isIcon,
                  'w-full pb-4': !isIcon,
                })}
              >
                <Image media={image.media} style={{ objectFit: 'contain' }} />
              </div>
            )}
            {headline && <div className="pb-2 font-bold">{headline}</div>}
            <Markdown text={text} className="text-center" />
            {buttonList.length > 0 &&
              buttonList.map((button, i) => (
                <Button
                  key={`content-slider-button-${i}`}
                  type={button.type}
                  text={button.label}
                  className="mt-4 w-full min-w-0"
                  referenceLinkClassName="w-full"
                  reference={button.reference}
                  disabled={button.disabled}
                />
              ))}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ContentSlider;
