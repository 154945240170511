import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { CSSTransition } from 'react-transition-group';

export interface Props {
  title?: string;
  body: React.JSX.Element;
  show: boolean;
  closeOverlay: () => void;
  mobileWidth?: string;
  bgColor?: string;
}

export default function Offcanvas({
  title,
  body,
  show,
  closeOverlay,
  mobileWidth = 'w-80',
  bgColor = 'neutral-100',
}: Props) {
  return (
    <CSSTransition in={show} timeout={400} unmountOnExit appear classNames="offcanvas-transition">
      <>
        <div
          className={`offcanvas fixed inset-y-0 right-0 z-[1050] flex ${mobileWidth} bg-${bgColor} flex-col overflow-y-auto p-4 transition-transform md:w-[550px]`}
        >
          <div className="offcanvas-header">
            <div
              className={`flex justify-between gap-5 p-3.5 text-xl font-bold leading-6 ${
                !title && 'absolute right-0 top-0'
              }`}
            >
              {title && <div>{title}</div>}
              <button className="text-slate-900" onClick={closeOverlay}>
                <XIcon className="size-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="offcanvas-body">{body}</div>
        </div>
        <div
          className="backdrop fixed left-0 top-0 z-[1040] h-screen w-screen bg-black-100 opacity-50"
          onClick={closeOverlay}
        ></div>
      </>
    </CSSTransition>
  );
}
