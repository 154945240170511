import React from 'react';

interface Props {
  readonly onClick: (data?: any) => void;
  readonly name?: string;
  readonly buttonText: string;
  readonly isDisabled?: boolean;
  readonly containerClassNames?: string;
  readonly classNames?: string;
}

const ButtonElement = ({ onClick, name, buttonText, isDisabled, containerClassNames, classNames }: Props) => {
  return (
    <div className={containerClassNames ? containerClassNames : 'mt-2'}>
      <button
        id={name}
        name={name}
        className={
          classNames ||
          'w-full rounded-md border-2 border-transparent bg-accent-400 px-4 py-3 text-sm font-medium text-white shadow-sm hover:bg-accent-500 focus:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400'
        }
        disabled={isDisabled}
        onClick={(e: React.FormEvent) => {
          e.preventDefault();
          onClick();
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ButtonElement;
