import { Fragment, useCallback, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoWarning as WarningIcon } from 'react-icons/io5';
import FormFieldButton from 'components/revelo-ui/forms/form-field-button';
import { useFormat } from 'helpers/hooks/useFormat';
import { useDarkMode } from 'frontastic';

export interface Props {
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => Promise<unknown>;
}

const DeleteConfirmationModal: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const { mode, theme } = useDarkMode();
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const [processing, setProcessing] = useState(false);

  const handleConfirm = useCallback(async () => {
    setProcessing(true);
    await onConfirm?.();
    setProcessing(false);
    onClose?.();
  }, [onConfirm]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} default fixed inset-0 z-10 overflow-y-auto`} onClose={onClose}>
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-left sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="absolute inset-0" onClick={onClose}>
              <div
                className="absolute left-1/2 top-1/2 w-[90%] max-w-[400px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
                onClick={(e) => e.stopPropagation()}
              >
                <div>
                  <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-yellow-100">
                    <WarningIcon className="size-6 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <>
                      <Dialog.Title className="text-lg font-medium leading-6 text-neutral-600">
                        {formatAccountMessage({
                          id: 'address.delete.confirm',
                          defaultMessage: 'Are you sure you want to delete this address?',
                        })}
                      </Dialog.Title>
                    </>
                  </div>
                </div>

                <div className="mt-5 flex items-center justify-between gap-3 sm:mt-6">
                  <FormFieldButton
                    buttonText={formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
                    onClick={handleConfirm}
                    isDisabled={processing}
                    classNames="inline-flex w-full justify-center rounded-md border border-transparent bg-accent-400 px-4 py-2 text-base font-medium text-white shadow-sm transition hover:bg-accent-600 focus:outline-none focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 disabled:bg-accent-100 sm:text-sm"
                  />
                  <FormFieldButton
                    buttonText={formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                    onClick={onClose}
                    classNames="inline-flex w-full justify-center rounded-md border border-transparent bg-neutral-500 px-4 py-2 text-base font-medium text-white shadow-sm transition hover:bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteConfirmationModal;
