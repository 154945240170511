/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { Variant } from '@Types/product/Variant';
import classnames from 'classnames';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import Price from '../../../../commercetools-ui/price';

export interface Props {
  variants: Variant[];
  selectedVariant?: Variant;
  onSelectVariant: (sku: string) => void;
  headerClassNames?: string;
  selectionClassNames?: string;
}

export const VariantSelection = ({
  variants,
  selectedVariant,
  onSelectVariant,
  headerClassNames,
  selectionClassNames,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });

  if (variants.length < 2) {
    return <></>;
  }

  return (
    <>
      <h2
        id="variants-heading"
        className={headerClassNames ?? 'text-md mb-4 font-semibold -tracking-[.01em] text-font-default'}
      >
        {formatMessage({ id: 'variants.title', defaultMessage: 'Variants title' })}
      </h2>

      <div className={selectionClassNames}>
        <RadioGroup value={selectedVariant} onChange={(e) => onSelectVariant(e.sku)}>
          {variants.map((variant) => (
            <div key={variant.id} className="mb-1 rounded bg-light-150">
              <RadioGroup.Option
                key={variant.sku}
                value={variant}
                className={({ active, checked }) =>
                  classnames(
                    'relative rounded p-3',
                    active || checked || selectedVariant?.sku === variant.sku
                      ? 'border border-primary-400 after:bg-primary-400'
                      : '',
                  )
                }
              >
                {({ active, checked }) => (
                  <RadioGroup.Label className="flex flex-auto shrink-0 items-center justify-between">
                    <div
                      className={classnames(
                        'absolute size-5 flex-none rounded-full border-4 border-gray-300 text-primary-400 hover:cursor-pointer hover:border-primary-400 focus:ring-primary-400',
                        "after:border-primary after:bg-primary after:absolute after:left-1/2 after:top-1/2 after:size-3 after:rounded-full after:content-[''] after:[transform:translate(-50%,-50%)]",
                        active || checked || selectedVariant?.sku === variant.sku
                          ? 'border-[1px] border-primary-400 after:bg-primary-400'
                          : '',
                      )}
                    />
                    <div className=" ml-12 flex flex-auto shrink-0 items-center justify-between text-sm md:ml-15 md:justify-start md:gap-11">
                      <div className="w-16">{variant.attributes?.frame_height_manufacturer}</div>
                      <div className="w-20">
                        {variant.attributes?.mileage_in_km ? `${variant.attributes?.mileage_in_km} km` : ``}
                      </div>
                      {variant?.price && (
                        <div className="w-20">
                          <Price price={variant.price} className="text-md" />
                        </div>
                      )}
                    </div>
                  </RadioGroup.Label>
                )}
              </RadioGroup.Option>
            </div>
          ))}
        </RadioGroup>
      </div>
    </>
  );
};

export default VariantSelection;
