import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { LineItem } from '@Types/cart/LineItem';
import { Variant } from '@Types/product/Variant';
import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { CartItemError } from '../../../components/revelo-ui/cart/errors/CartItemError';
import { DiscountError } from '../../../components/revelo-ui/cart/errors/DiscountError';
import { CheckoutResponseError } from '../../../components/revelo-ui/checkout/errors/CheckoutResponseError';
import { PaymentResponseError } from '../../../components/revelo-ui/checkout/errors/PaymentResponseError';
import { PaymentHandler } from '../../../components/revelo-ui/checkout/utils/PaymentHandler';
import { DiscountStatus } from '../../../components/revelo-ui/types/DiscountStatus';

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
  payment?: string;
};

export const cartItems = () => {
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions);
};

export const orderHistory = async () => {
  return await fetchApiHub('/action/cart/getOrders');
};

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings');
};

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods');
};

export const getAvailableShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getAvailableShippingMethods');
};

export const getShippingMethodMapping = async () => {
  return await fetchApiHub('/action/cart/getShippingMethodMapping');
};

export const getPaymentMethods = async () => {
  const paymentMethods = await fetchApiHub('/action/payment/getPaymentMethods');
  const cart = await fetchApiHub('/action/cart/getCart');

  mutate('/action/cart/getCart', cart, { revalidate: false });

  return paymentMethods;
};

export const checkCartItemAvailability = async () => {
  const res = await fetchApiHub('/action/cart/checkCartItemAvailability');
  mutate('/action/cart/getCart', res.cart, { revalidate: false });

  return res.stockError;
};

export const addItem = async (variant: Variant, quantity: number) => {
  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  };

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const updateItemQuantity = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const updateItemShippingMethod = async (
  lineItemId: string,
  shippingMethodId: string,
  shippingAddress: Address,
) => {
  return updateItemShippingMethods([
    {
      lineItemId: lineItemId,
      shippingDetails: {
        shippingMethodId: shippingMethodId,
        shippingAddress: shippingAddress,
      },
    },
  ]);
};

export const updateItemShippingMethods = async (items: LineItem[]) => {
  const payload = items
    .filter(
      (item) => item.lineItemId && item.shippingDetails?.shippingMethodId && item.shippingDetails?.shippingAddress,
    )
    .map((item) => ({
      lineItemId: item.lineItemId,
      shippingDetails: item.shippingDetails,
    }));

  const res = await fetchApiHub(
    '/action/cart/updateLineItems',
    {
      method: 'POST',
    },
    payload,
  );

  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const response = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );

  if (!!response.isError) {
    if (response.errorMessage === 'InvalidDiscountCodes') {
      throw new DiscountError(response.errorMessage, response.payload ?? []);
    }
    throw new Error(response.errorMessage ?? 'Failed to update cart');
  }

  mutate('/action/cart/getCart', response, { revalidate: false });

  return response;
};

export const refreshCart = async (): Promise<Cart> => {
  const res = await fetchApiHub('/action/cart/refreshCart', {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'GET',
  });

  mutate('/action/cart/getCart', res, { revalidate: false });

  return res;
};

export const setPaymentMethod = async (paymentMethodId: string) => {
  const payload = {
    paymentMethod: {
      id: paymentMethodId,
    },
  };

  const response = await fetchApiHub(
    '/action/payment/setPaymentMethod',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', response, { revalidate: false });
};

export const setShippingMethod = async (shippingMethodId: string, lineItemId?: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
    lineItem: lineItemId ? { id: lineItemId } : null,
  };

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const redeemDiscountCode = async (code: string): Promise<DiscountStatus> => {
  const response = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    { code: code },
  );

  if (!!response.isError) {
    return { code: 'error', message: response.errorMessage };
  }

  mutate('/action/cart/getCart', response, { revalidate: false });

  return { code: 'redeemed' };
};

export const removeDiscountCode = async (discount: Discount): Promise<DiscountStatus> => {
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    { discountId: discount.discountId },
  );

  mutate('/action/cart/getCart', res, { revalidate: false });

  return { code: 'removed' } as DiscountStatus;
};

export const triggerPayment = async () => {
  const response = await fetchApiHub('/action/payment/initCartPayment', {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'POST',
  });

  if (response?.statusCode && +response.statusCode > 299) {
    const message = response.message || 'unknown error';
    if (response.items) {
      throw new CartItemError(message, response.statusCode, response.items);
    }
    throw new PaymentResponseError(message, response.statusCode, response.field);
  }

  mutate('/action/cart/getCart', response, { revalidate: false });

  return PaymentHandler.getMolliePayment(response);
};

export const syncCartPayment = async () => {
  const response = await fetchApiHub('/action/payment/syncCartPayment', {
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
    method: 'POST',
  });

  if (response?.statusCode && +response.statusCode > 299) {
    throw new PaymentResponseError(response.message || 'payment synchronization error', response.statusCode);
  }

  mutate('/action/cart/getCart', response, { revalidate: false });
};

export const checkout = async () => {
  const response = await fetchApiHub('/action/cart/checkout', {
    method: 'POST',
  });

  if (response?.statusCode && +response.statusCode > 299) {
    const message = response.message || 'unknown error';
    if (response.items) {
      throw new CartItemError(message, response.statusCode, response.items);
    }
    throw new CheckoutResponseError(message, response.statusCode);
  }

  if (response?.code && response?.errors?.length > 0) {
    const error = response.errors.find((error) => error !== undefined);
    throw new CheckoutResponseError(error.message, response.code);
  }

  mutate('/action/cart/getCart', response?.cart ?? response);

  return response?.trustcard ?? undefined;
};
