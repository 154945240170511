import React from 'react';
import { Banner as BannerType } from '@Types/content/Banner';
import { LocaleStorage } from '../../../../frontastic';
import Image from '../../../../frontastic/lib/image';
import { ReferenceLink } from '../../../../helpers/reference';
import DataValidator from '../../../../helpers/utils/dataValidator';
import { mapLanguage } from '../../../../project.config';
import Button from '../button';
import Markdown from '../markdown';

const Banner: React.FC<BannerType> = ({
  backgroundImage,
  backgroundImageFit,
  backgroundColor,
  bannerText,
  textColor,
  buttonLabel,
  buttonStyle,
  buttonLink,
  bannerLink,
  customClassName,
}) => {
  const locale = mapLanguage(LocaleStorage?.locale ?? 'de');
  const bgImgAlt = backgroundImage?.title !== undefined ? backgroundImage?.title[locale] : 'banner background image';
  backgroundImageFit = backgroundImageFit || 'cover';
  customClassName = customClassName || '';
  const txtColor = textColor || '#161038';
  const validBannerLink = DataValidator.isValidReference(bannerLink) ? bannerLink : undefined;
  const validButtonLink = DataValidator.isValidReference(buttonLink) ? buttonLink : undefined;

  return (
    <div
      className={`banner relative flex w-full flex-col justify-center p-4 text-center ${customClassName}`}
      style={{ backgroundColor: backgroundColor }}
    >
      {validBannerLink && <ReferenceLink target={validBannerLink} className="absolute inset-0 z-[2]" />}

      {backgroundImage?.media && (
        <div className="banner__bg-img-wrapper absolute inset-0 z-0">
          <Image
            media={backgroundImage?.media}
            alt={bgImgAlt}
            layout="fill"
            objectFit={backgroundImageFit}
            className="banner__bg-img"
          />
        </div>
      )}

      {bannerText && (
        <div className="banner__text relative z-[1]" style={{ color: txtColor }}>
          <Markdown text={bannerText} />
        </div>
      )}

      {buttonLabel && validButtonLink && (
        <Button
          type={buttonStyle}
          text={buttonLabel}
          reference={validButtonLink}
          className={'banner__button relative z-[3] mt-7.5 !min-w-0'}
        />
      )}
    </div>
  );
};

export default Banner;
