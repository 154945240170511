import React, { FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { CurrencyEuroIcon } from '@heroicons/react/outline';
import { Slider } from '@mui/material';
import { RangeFacet } from '@Types/result/RangeFacet';
import { useFormat } from 'helpers/hooks/useFormat';

type RangeInputValues = [number, number];

export type PriceRangeProps = {
  facet: RangeFacet;
  currency: string;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  onChange: (values: [number, number], shouldSubmit: boolean) => void;
};

const PriceInput = ({ value, disabled, onChange, onBlur }) => (
  <div className="relative w-2/6">
    <input
      type="text"
      className="block h-6 w-full appearance-none rounded-lg border-2 border-gray-300 p-2 pr-4 text-xs focus:border-accent-400 focus:outline-none focus:ring-accent-400"
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
      onBlur={(event) => onBlur(event.target.value)}
      value={value}
    />
    <span className="absolute right-2 top-1/2 block size-3 -translate-y-1/2 text-neutral-600">
      <CurrencyEuroIcon />
    </span>
  </div>
);

const CustomSliderStyles = {
  '& .MuiSlider-thumb': {
    color: '#FFDDD3',
    width: '20px',
    height: '20px',
    border: '2px solid #0F4A48',
    borderRadius: '50%',
  },
  '& .MuiSlider-thumb:hover': {
    boxShadow: 'unset',
  },
  '& .MuiSlider-thumb:active': {
    boxShadow: '0px 0px 0px 4px rgba(25, 118, 210, 0.16)',
  },
  '& .MuiSlider-track': {
    color: '#0F4A48',
  },
  '& .MuiSlider-rail': {
    color: '#0F4A48',
  },
};

const RangeFilter: FC<PriceRangeProps> = ({ facet, onChange, currency, disabled = false }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });
  const widthRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [minPrice, setMinPrice] = useState<number>(undefined);
  const [maxPrice, setMaxPrice] = useState<number>(undefined);
  const [values, setValues] = useState<RangeInputValues>([minPrice, maxPrice]);

  const updateValues = (updatedValues: RangeInputValues, checkMinMax: boolean, shouldSubmit = true) => {
    const [minValue, maxValue] = updatedValues.map((value, index) => (isNaN(value) ? values[index] : +value));

    const [firstValue, secondValue] = checkMinMax && minValue > maxValue ? [maxValue, minValue] : [minValue, maxValue];

    setValues([firstValue, secondValue]);

    if (!disabled) {
      onChange([firstValue * 100, secondValue * 100], shouldSubmit);
    }
  };

  const convertCents = (amountInCents: number) => Math.trunc(amountInCents / 100);

  useEffect(() => {
    if (facet) {
      const { min, max, minSelected, maxSelected } = facet as RangeFacet;
      const minConverted = convertCents(min);
      const maxConverted = convertCents(max);

      setMinPrice(minConverted);
      setMaxPrice(maxConverted);

      // Setting default values
      if (minSelected && maxSelected) {
        updateValues([convertCents(minSelected), convertCents(maxSelected)], false);
      } else {
        updateValues([minConverted, maxConverted], false, false);
      }
    }

    setInputWidth(widthRef.current.clientWidth);
  }, [router, facet]);

  return (
    <div className="grid w-full gap-5">
      <div className="flex justify-between" ref={widthRef}>
        <>
          <PriceInput
            value={values[0]}
            disabled={disabled}
            onChange={(value) => updateValues([value, values[1]], false)}
            onBlur={(value) => {
              updateValues([value, values[1]], true);
            }}
          />
          {formatMessage({ id: 'filter.to', defaultMessage: 'bis' })}
          <PriceInput
            value={values[1]}
            disabled={disabled}
            onChange={(value) => updateValues([values[0], value], false)}
            onBlur={(value) => updateValues([values[0], value], true)}
          />
        </>
      </div>
      {minPrice && (
        <Slider
          value={values}
          size="small"
          onChange={(event: Event, newValue: RangeInputValues) => updateValues(newValue, false)}
          valueLabelDisplay="off"
          min={minPrice}
          max={maxPrice}
          sx={CustomSliderStyles}
          style={{ width: 'calc(100% - 20px)', margin: '0 auto' }}
        />
      )}
    </div>
  );
};

export default RangeFilter;
