import React, { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Brand } from '@Types/brands/Brand';
import { AttributeGroup } from '@Types/product/AttributeGroup';
import { Category } from '@Types/product/Category';
import { Money } from '@Types/product/Money';
import { OpticalConditionModal } from '@Types/product/OpticalConditionModal';
import { Variant } from '@Types/product/Variant';
import { DynamicPageSeoProperties } from '@Types/project/DynamicPageSeoProperties';
import { Trustbadge } from '@Types/trustedshops/Trustbadge';
import classnames from 'classnames';
import Breadcrumb from 'components/revelo-ui/breadcrumb';
import { useFormat } from 'helpers/hooks/useFormat';
import { desktop, smallTablet } from '../../../../helpers/utils/screensizes';
import { TaxHandler } from '../../../revelo-ui/utils/TaxHandler';
import TrustbadgeComponent from '../../../trusted-shops/trustbadge';
import { TrustbadgeUtils } from '../../../trusted-shops/utils/TrustbadgeUtils';
import { Tooltip } from '../../types/Tooltip';
import { TooltipHandler } from '../../utils/TooltipHandler';
import { VariantSortUtil } from '../../utils/VariantSortUtil';
import BenefitsList from '../benefits-list';
import CartForm from './cart-form';
import MainAttributes from './main-attributes';
import PriceInfo from './price-info';
import { ProductSlider } from './product-slider';
import VariantAttributes from './variant-attributes';
import VariantSelection from './variant-selection';
import { useTrustedShops, useBrands } from '../../../../frontastic';
import Image from '../../../../frontastic/lib/image';
import useMediaQuery from '../../../../helpers/hooks/useMediaQuery';
import Accordion from '../../content/accordion';
import { Accordion as AccordionType } from '../../types/Accordion';

export interface Props {
  product: UIProduct;
  onAddToCart: (variant: Variant, quantity: number) => Promise<void>;
  onAddToWishlist: () => void;
  variant: Variant;
  onChangeVariantIdx: (idx: number) => void;
  quickBuyEnabled?: boolean;
  benefits?: object;
  mainAttributes?: string[];
  opticalConditionModal?: OpticalConditionModal;
  attributeGroups?: AttributeGroup[];
  mobile?: boolean;
  tooltips?: Tooltip[];
  imageFullScreenEnabled: boolean;
  hideImageDisclaimer?: boolean;
  seoProperties?: DynamicPageSeoProperties;
  tsBadgeText?: string;
  tsBadgeToggle?: boolean;
  tabs: (AccordionType & { tabType: 'refurbishment' | 'description' | 'generic'; tabWhitelist?: string })[];
}

export type UIProduct = {
  productId: string;
  name: string;
  variants: Variant[];
  price?: Money;
  images?: UIImage[];
  colors?: UIColor[];
  sizes?: UISize[];
  description: string;
  details?: UIDetail[];
  isOnWishlist?: boolean;
  _url: string;
  categories: Category[];
};

interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}

export interface UIColor {
  name?: string;
  key?: string;
  bgColor?: string;
  selectedColor?: string;
}

export interface UISize {
  label: string;
  key: string;
}

export interface UIVariant {
  sku: string;
  id?: string;
}

interface UIDetail {
  name: string;
  items: string[];
}

export default function ProductDetail({
  product,
  onAddToCart,
  onAddToWishlist,
  variant,
  onChangeVariantIdx,
  quickBuyEnabled,
  hideImageDisclaimer,
  benefits,
  opticalConditionModal,
  attributeGroups,
  mobile,
  tooltips = [],
  imageFullScreenEnabled,
  tsBadgeText,
  tsBadgeToggle,
  seoProperties,
  tabs,
}: Props) {
  //next/router
  const router = useRouter();

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const tooltipHandler = new TooltipHandler(tooltips);

  const [isDesktopSize] = useMediaQuery(desktop);
  const [isMobile] = useMediaQuery(smallTablet);
  const [showTrustbadge, setShowTrustbadge] = useState<boolean>(false);
  const [trustbadge, setTrustbadge] = useState<Trustbadge | null>();
  const [expandProperties, setExpandProperties] = useState<boolean>(false);
  const [brandDetails, setBrandDetails] = useState<Brand>();
  const { getTrustbadge } = useTrustedShops();
  const { getBrandDetails } = useBrands();
  const ref = useRef<HTMLElement>();

  const getTabChildren = (tabType: 'refurbishment' | 'description' | 'generic', tabWhitelist: string) => {
    switch (tabType) {
      case 'refurbishment':
        if (variant?.attributes?.spare_parts_replaced?.length && tabWhitelist?.length) {
          const whitelistedAttributes = tabWhitelist.split(';');

          return (
            <ul className="list-disc">
              {variant.attributes.spare_parts_replaced.map(
                (value, i) =>
                  whitelistedAttributes.includes(value) && (
                    <li key={`spare_parts_replaced-${i}`} className="p-0">
                      {value}
                    </li>
                  ),
              )}
            </ul>
          );
        }
        return undefined;
      case 'description':
        if (product?.description?.length) {
          return <div className="-mt-4" dangerouslySetInnerHTML={{ __html: product.description }} />;
        }
        return undefined;
      default:
        return undefined;
    }
  };

  const headline =
    Object.keys(variant.attributes).length > 0
      ? '<span class="font-semibold">' +
        variant?.attributes?.brand +
        ' ' +
        variant?.attributes?.model_name +
        '</span> - ' +
        variant?.attributes?.model_year
      : product.name;

  const availableVariants =
    variant?.availableQuantity === 0
      ? [variant]
      : product.variants?.filter((variant) => variant.availableQuantity > 0).sort(VariantSortUtil.sortVariants) || [];

  const availableSizes = VariantSortUtil.getAvailableSizes(availableVariants);

  const onSelectVariant = (sku: string) => {
    const index = product?.variants?.findIndex((variant) => variant?.sku === sku);
    if (index !== -1) {
      onChangeVariantIdx(index);
    }
  };

  const onQuickBuy = async (variant: Variant) => {
    await onAddToCart(variant, 1);
    router.push('/checkout');
  };

  const [category] = product.categories;

  const toggleVariantAttributes = () => {
    setExpandProperties(!expandProperties);

    if (expandProperties) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (trustbadge === undefined) {
      getTrustbadge('PDP').then((trustbadge) => setTrustbadge(trustbadge));
      return;
    }
    setShowTrustbadge(TrustbadgeUtils.includeTrustbadge(trustbadge, isDesktopSize));
  }, [trustbadge, getTrustbadge, isMobile]);

  useEffect(() => {
    if (variant.attributes['brand']?.[0]) {
      const getBrand = async () => {
        return await getBrandDetails(variant.attributes.brand[0]);
      };
      getBrand().then((brand) => setBrandDetails(brand));
    }
  }, []);

  return (
    <div>
      <Head>
        <title>
          {seoProperties?.title?.length
            ? seoProperties.title
            : formatMessage({
                id: 'meta.pdp.title',
                values: {
                  brand: variant?.attributes?.brand,
                  modelName: variant?.attributes?.model_name,
                  modelYear: variant?.attributes?.model_year,
                },
              })}
        </title>
        <meta
          name="description"
          content={
            seoProperties?.description?.length
              ? seoProperties.description
              : formatMessage({
                  id: 'meta.pdp.description',
                  values: {
                    brand: variant?.attributes?.brand,
                    modelName: variant?.attributes?.model_name,
                    category: category?.breadcrumbs[category.breadcrumbs.length - 1].name,
                  },
                })
          }
        />
      </Head>
      <div className="mt-4 bg-white px-1 sm:px-3 lg:px-6">
        {category && <Breadcrumb breadcrumbs={category.breadcrumbs} isPDP />}
      </div>
      <div className="mx-auto max-w-2xl lg:max-w-7xl lg:px-6">
        <div className="lg:grid lg:grid-cols-5 lg:items-start lg:gap-x-8">
          <div className="md:py-4 lg:sticky lg:top-0 lg:z-10 lg:col-span-3">
            <div className="mt-4 flex justify-between lg:px-10">
              <div className="h-5">
                {brandDetails?.brandLogo && (
                  <Image
                    src={brandDetails.brandLogo.file}
                    alt={brandDetails.brandLogo.alt}
                    intrinsicSize={20}
                    className="size-full object-cover"
                  />
                )}
              </div>
              {variant.availableQuantity === 1 && (
                <div className="flex items-center rounded bg-accent-300 px-1 py-0.5 text-xs leading-4 text-white">
                  {formatProductMessage({
                    id: 'badgeOneStockLeft',
                    defaultMessage: 'nur 1 auf Lager',
                  })}
                </div>
              )}
            </div>
            {!mobile && <div id="magnifyContainer" className="absolute left-full" />}
            <div className="lg:pl-2">
              <ProductSlider
                images={product.images}
                imageFullScreenEnabled={imageFullScreenEnabled}
                key={`product-slider--${product.name}`}
              />
              {!hideImageDisclaimer && (
                <div className="mt-2 w-full lg:px-5">
                  <div className="text-xs font-light md:p-2">
                    {formatProductMessage({
                      id: 'image.disclaimer',
                      defaultMessage: 'Die Bilder zeigen den tatsächlichen Zustand des Rads.',
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Product info */}
          <div className="relative mt-5 flex flex-col md:mt-0 md:py-4 lg:col-span-2 lg:pr-2">
            {/* !mobile && <div id="magnifyContainer" className="absolute" />*/}
            <div className="flex flex-wrap justify-between lg:order-1">
              <h1
                className="text-xl leading-6 -tracking-[.01em] text-gray-900"
                dangerouslySetInnerHTML={{ __html: headline }}
              ></h1>
              <div className="basis-full text-xs font-light">Nr. {variant?.attributes['SKU'] ?? variant?.sku}</div>
            </div>

            <div className="-mx-4 mt-4 rounded-md bg-neutral-100 p-4 -tracking-[.01em] md:mx-0 lg:order-3">
              <h2 className="sr-only">
                {formatProductMessage({ id: 'product?.info', defaultMessage: 'Product information' })}
              </h2>

              <PriceInfo
                price={variant?.price ?? product.price}
                recommendedPrice={variant?.recommendedPrice}
                discountedPrice={variant?.discountedPrice}
                useDifferentialTaxation={TaxHandler.usesDifferentialTaxation(variant)}
                tooltips={tooltipHandler}
              />
            </div>

            <section aria-labelledby="main-attributes" className=" lg:order-2">
              {variant && (
                <MainAttributes
                  variant={variant}
                  availableSizes={availableSizes}
                  onSelectVariant={onSelectVariant}
                  tooltips={tooltipHandler}
                  opticalConditionModal={opticalConditionModal}
                />
              )}
            </section>

            <section aria-labelledby="benefits" className=" lg:order-4">
              <BenefitsList
                benefits={benefits}
                classNames="-mx-4 bg-neutral-100 px-4 pt-4 pb-2 md:mx-0 md:px-0 text-neutral-900 lg:bg-transparent"
              />
            </section>

            {variant && (
              <section aria-labelledby="cart-form" className="block lg:order-5">
                <CartForm
                  formClassNames="my-3"
                  variant={variant}
                  onAddToCart={onAddToCart}
                  onQuickBuy={onQuickBuy}
                  quickBuyEnabled={quickBuyEnabled}
                />
                {tsBadgeToggle && showTrustbadge && !isDesktopSize && (
                  <div className="inline-flex p-2">
                    {!isMobile ? (
                      <div
                        id="trustbadgeCustomMobileContainer"
                        className="m-auto max-h-8 w-full max-w-8 overflow-hidden"
                      />
                    ) : (
                      <div id="trustbadgeCustomContainer" className="m-auto max-h-8 w-full max-w-8 overflow-hidden" />
                    )}
                    <TrustbadgeComponent trustbadge={trustbadge} />
                    <p className="ml-2 text-sm">{tsBadgeText}</p>
                  </div>
                )}
              </section>
            )}

            {availableVariants.length > 1 && (
              <section aria-labelledby="variants-heading" className="my-4  lg:order-6">
                <VariantSelection
                  variants={availableVariants}
                  selectedVariant={variant}
                  onSelectVariant={onSelectVariant}
                />
              </section>
            )}

            {variant && (
              <section
                aria-labelledby="details-heading"
                className="prose -mx-4 border-primary-100 px-4 md:mx-0 md:border md:pb-4 lg:order-7"
                ref={ref}
              >
                <h2 id="details-heading" className="my-4 text-xl font-semibold -tracking-[.01em]">
                  {formatProductMessage({
                    id: 'variants.properties.title',
                    defaultMessage: 'Properties',
                  })}
                </h2>

                <VariantAttributes variant={variant} groups={attributeGroups} isExpanded={expandProperties} />
                <button
                  type="button"
                  className={classnames('mb-4 mt-2 w-full text-center text-accent-600 underline', {
                    'shadow-[0px_-30px_10px_5px_rgb(255,255,255,0.95)]': !expandProperties,
                  })}
                  onClick={() => toggleVariantAttributes()}
                >
                  {formatProductMessage({
                    id: expandProperties ? 'ShowLessSpecifications' : 'ShowMoreSpecifications',
                    defaultMessage: expandProperties ? 'Weniger anzeigen' : 'Mehr Spezifikationen anzeigen',
                  })}
                </button>
              </section>
            )}
            {tabs?.length && (
              <div className="relative -mx-4 mt-5 md:mx-0 md:mt-0 md:py-4 lg:order-8 lg:col-span-2">
                {tabs.map((tab, index) => (
                  <Accordion
                    key={`pdp-tab-${index}`}
                    accordion={{
                      ...tab,
                      content: !getTabChildren(tab.tabType, tab.tabWhitelist) ? tab.content : '',
                      button:
                        tab.tabType === 'description' && category
                          ? {
                              ...tab.button[0],
                              label: tab.button[0].label.replace(/[{]+[a-zA-Z0-9]+[}]+/, category.name),
                              reference: {
                                link: category._url,
                                type: 'link',
                                openInNewWindow: tab.button[0].reference.openInNewWindow,
                              },
                            }
                          : tab.button[0],
                      showContent: tab.tabType === 'refurbishment' || index === 0,
                    }}
                    classNames={{
                      wrapper: `border border-neutral-300 bg-neutral-100 ${index > 0 ? 'border-t-0' : ''}`,
                      contentWrapper: 'p-3 prose-sm',
                    }}
                    hasNoBackground
                    shouldScrollIntoView={false}
                  >
                    {getTabChildren(tab.tabType, tab.tabWhitelist)}
                  </Accordion>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
