import React, { ReactElement } from 'react';
import classnames from 'classnames';

type Props = {
  type: 'primary' | 'secondary' | 'hollow';
  text: string;
  icon: ReactElement;
  iconPosition: 'left' | 'right';
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

const IconButton: React.FC<Props> = ({
  type,
  text,
  icon,
  iconPosition = 'left',
  onClick,
  className,
  disabled = false,
}) => {
  const button = (
    <button
      type="button"
      className={classnames(
        className,
        'rounded-md border-2 border-transparent p-3 text-sm font-semibold transition duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
          'bg-accent-400 text-white hover:bg-accent-500 focus:bg-accent-500 focus:ring-accent-400 disabled:bg-accent-100':
            type === 'primary',
          'bg-primary-400 text-white hover:bg-primary-500 focus:bg-primary-500 focus:ring-primary-400 disabled:bg-primary-100':
            type === 'secondary',
          'border-hollow-dark bg-hollow text-hollow-text hover:border-hollow-text hover:bg-hollow-hover focus:border-hollow-text focus:bg-hollow-hover focus:ring-hollow-dark disabled:bg-hollow disabled:text-hollow-dark':
            type === 'hollow',
        },
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex w-full">
        {iconPosition === 'left' && <div className="grow-0">{icon}</div>}
        <div className={`grow text-${iconPosition === 'left' ? 'right' : 'left'}`}>{text}</div>
        {iconPosition === 'right' && <div className="grow-0">{icon}</div>}
      </div>
    </button>
  );

  return button;
};

export default IconButton;
