import React, { useEffect, useState } from 'react';
import { Trustbadge } from '@Types/trustedshops/Trustbadge';
import Column from './column';
import { renderItem } from './renderItem';
import { useTrustedShops } from '../../../frontastic';
import useMediaQuery from '../../../helpers/hooks/useMediaQuery';
import { desktop, smallTablet } from '../../../helpers/utils/screensizes';
import TrustbadgeComponent from '../../trusted-shops/trustbadge';
import { TrustbadgeUtils } from '../../trusted-shops/utils/TrustbadgeUtils';
import { FooterColumn } from '../types/FooterColumn';
import { FooterLink } from '../types/FooterLink';

export interface FooterProps {
  columns: FooterColumn[];
  copyright?: string;
  copyrightLinks?: FooterLink[];
  enableMobileTrustBadge: boolean;
}

const Footer: React.FC<FooterProps> = ({ columns, copyright, copyrightLinks, enableMobileTrustBadge }) => {
  const [showTrustbadge, setShowTrustbadge] = useState<boolean>(false);
  const [trustbadge, setTrustbadge] = useState<Trustbadge | null>();

  const { getTrustbadge } = useTrustedShops();
  const useHorizontalGrid = useMediaQuery('(min-width: 768px)');
  const [isDesktopSize] = useMediaQuery(desktop);
  const [isMobile] = useMediaQuery(smallTablet);

  const gridClass = useHorizontalGrid
    ? `grid-cols-1 sm:grid-cols-2 lg:grid-cols-${columns.length.toString()}`
    : 'grid-cols-1';

  useEffect(() => {
    if (trustbadge === undefined) {
      getTrustbadge().then((trustbadge) => setTrustbadge(trustbadge));
      return;
    }
    setShowTrustbadge(TrustbadgeUtils.includeTrustbadge(trustbadge, isDesktopSize));
  }, [isDesktopSize, trustbadge, getTrustbadge]);

  return (
    <footer aria-label="footer">
      <div className="w-full bg-gray-100 px-4 dark:bg-transparent md:mx-0 lg:px-4">
        <div className={`mx-auto max-w-6xl px-2 py-10 xl:grid xl:grid-cols-2 xl:gap-8`}>
          {enableMobileTrustBadge && (
            <div className="mb-10">
              <div id="trustbadgeCustomMobileContainer" />
              {showTrustbadge && <TrustbadgeComponent trustbadge={trustbadge} />}
            </div>
          )}
          <div className={`grid w-full gap-10 ${gridClass} xl:col-span-2`}>
            {columns?.map((column, index) => (
              <div key={index} className="">
                <Column column={column} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {copyright && (
        <div className="flex place-content-between border-t border-gray-200 bg-primary-400 p-4 sm:px-10">
          <p className="text-xs text-white sm:text-sm">© {copyright}</p>
          <ul className="flex">
            {copyrightLinks?.map((link, i) => (
              <li key={i} className="text-xs">
                {renderItem({ ...link, type: 'reference' }, 'text-xs text-white sm:text-sm hover:text-slate-200')}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!enableMobileTrustBadge && showTrustbadge && <TrustbadgeComponent trustbadge={trustbadge} />}
    </footer>
  );
};

export default Footer;
