import React from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { DropdownProps } from 'components/commercetools-ui/dropdown';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import CartItemAttributes from './attributes';
import imageUrlResize from '../../../../../helpers/imageUrlResize';
import TrashIcon from '../../../../icons/trash';
import { TaxHandler } from '../../../utils/TaxHandler';

interface Props {
  lineItem: LineItem;
  goToProductPage: (_url: string) => void;
  editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  removeItem: (lineItemId: string) => void;
}

const CartItem = ({ lineItem, goToProductPage, editItemQuantity, removeItem }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const taxClass = TaxHandler.getTaxCssClassForItem(lineItem);

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    removeItem(id);
  };

  return (
    <div
      className="relative mb-1.5 flex cursor-pointer gap-2.5 rounded border border-neutral-300 px-4 py-5 transition-colors md:mb-0 md:border md:hover:border-neutral-100 lg:gap-24 lg:px-12 lg:py-4"
      onClick={() => goToProductPage(lineItem._url)}
    >
      <div className="flex basis-[35%] items-center">
        <Image src={imageUrlResize(lineItem.variant.images[0], 'medium')} alt={lineItem.name} />
      </div>

      <div className="col-span-2 flex basis-[65%] items-center">
        <div className="flex flex-wrap">
          <div className="mb-1 pr-6 text-sm font-semibold text-gray-900 hover:text-gray-800 lg:mb-2 lg:text-base lg:leading-6">
            <span className="text-sm md:block lg:text-base lg:font-normal">
              {lineItem.variant.attributes.brand[0]}{' '}
            </span>
            {lineItem.variant.attributes.model_name}
          </div>

          <div className="mb-2.5 inline-flex basis-full">
            <CartItemAttributes
              variant={lineItem.variant}
              attributes={['frame_height_manufacturer', 'mileage_in_km']}
            />
          </div>

          <div className="basis-full md:hidden">
            {lineItem.discountedPrice ? (
              <>
                <Price price={lineItem.price} className="text-sm font-semibold text-gray-900 line-through" />
                <Price
                  price={lineItem.discountedPrice}
                  className={`ml-2 mt-1 text-sm font-semibold text-accent-400 ${taxClass}`}
                />
              </>
            ) : (
              <Price
                price={lineItem.price}
                className={`text-sm font-semibold text-gray-900 lg:text-accent-300 ${taxClass}`}
              />
            )}
          </div>

          <div className="mb-3 mt-auto hidden md:flex md:items-end md:justify-between lg:mb-0">
            <div className="hidden md:flex">
              {lineItem.discountedPrice ? (
                <>
                  <Price
                    price={lineItem.price}
                    className="text-sm font-semibold text-gray-900 line-through lg:text-base"
                  />
                  <Price
                    price={lineItem.discountedPrice}
                    className={`ml-2 mt-1 text-sm font-semibold text-accent-400 ${taxClass}`}
                  />
                </>
              ) : (
                <Price
                  price={lineItem.price}
                  className={`text-sm font-semibold text-gray-900 lg:text-base lg:text-accent-300 ${taxClass}`}
                />
              )}
            </div>
          </div>

          <div className="absolute right-0 top-0">
            <button
              type="button"
              onClick={(e) => onButtonClick(e, lineItem.lineItemId)}
              className="inline-flex px-4 py-5 text-gray-400 hover:text-gray-500 md:p-5"
            >
              <span className="sr-only">{formatMessage({ id: 'remove', defaultMessage: 'Remove' })}</span>
              <TrashIcon className="size-5 lg:size-7" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
