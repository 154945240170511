import { useEffect, useState } from 'react';
import DesktopOrderSummary from './desktop';
import MobileOrderSummary from './mobile';
import { useCart } from '../../../../../frontastic';
import { useCheckout } from '../../provider';
import { OrderSummaryTotals } from '../../types';
import { CartSummaryHandler } from '../../utils/CartSummaryHandler';

type Props = {
  displayDesktop?: boolean;
  displayMobile?: boolean;
  deliveryTime?: string;
};

const OrderSummary = ({ displayDesktop = false, displayMobile = false, deliveryTime }: Props) => {
  const { data: cart } = useCart();
  const { calculatingShipping, deliveryCountry } = useCheckout();
  const [orderTotals, setOrderTotals] = useState<OrderSummaryTotals>({});

  // todo - use proper frontastic configuration
  const maxVoucherLimit = 1;

  useEffect(() => {
    setOrderTotals(
      calculatingShipping
        ? {}
        : {
            subTotal: CartSummaryHandler.getSubTotal(cart),
            discountTotal: CartSummaryHandler.getDiscountTotal(cart),
            shippingTotal: CartSummaryHandler.getShippingTotal(cart, deliveryCountry),
          },
    );
  }, [calculatingShipping, cart?.shippingAddress?.country, deliveryCountry]);

  return (
    <>
      {displayDesktop && (
        <DesktopOrderSummary orderTotals={orderTotals} maxVoucherLimit={maxVoucherLimit} deliveryTime={deliveryTime} />
      )}
      {displayMobile && (
        <MobileOrderSummary orderTotals={orderTotals} maxVoucherLimit={maxVoucherLimit} deliveryTime={deliveryTime} />
      )}
    </>
  );
};

export default OrderSummary;
