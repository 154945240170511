import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { getFieldError } from '../../utils/FormFieldUtils';
import ErrorElement from '../form-elements/ErrorElement';
import InputElement from '../form-elements/InputElement';
import LabelElement from '../form-elements/LabelElement';

interface Props {
  readonly onChange: (propName: string, newValue: string) => void;
  readonly value: string;
  readonly name: string;
  readonly inputType?: string;
  readonly inputAutoComplete?: string;
  readonly isDisabled?: boolean;
  readonly label?: string | React.JSX.Element;
  readonly smallLabel?: string | React.JSX.Element;
  readonly containerClassNames?: string;
  readonly labelClassNames?: string;
  readonly smallLabelClassNames?: string;
  readonly inputContainerClassNames?: string;
  readonly inputClassNames?: string;
  readonly formId?: string;
  readonly validation?: RegisterOptions;
  readonly inverseLabel?: boolean;
  readonly placeholder?: string;
  readonly isReadOnly?: boolean;
}

const FormFieldInput = ({
  onChange,
  value,
  name,
  inputType,
  inputAutoComplete,
  label,
  isDisabled,
  containerClassNames,
  labelClassNames,
  smallLabelClassNames,
  inputContainerClassNames,
  inputClassNames,
  smallLabel,
  formId,
  validation,
  inverseLabel,
  placeholder,
  isReadOnly,
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = getFieldError(name, errors);

  const inputElement = (
    <InputElement
      onChange={onChange}
      value={value}
      name={name}
      type={inputType}
      autoComplete={inputAutoComplete}
      containerClassNames={inputContainerClassNames}
      placeholder={placeholder}
      classNames={
        inputClassNames ||
        `block w-full rounded-lg border-2 px-4 py-3 shadow border-${
          fieldError
            ? 'red-500 focus:border-red-500 focus:ring-red-500'
            : 'gray-300 focus:border-primary-400 focus:ring-primary-400'
        } sm:text-sm`
      }
      validation={validation}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    />
  );

  const labelElement = (
    <LabelElement
      fieldId={name}
      formId={formId}
      label={label}
      smallLabel={smallLabel}
      classNames={labelClassNames}
      smallLabelClassNames={smallLabelClassNames}
    />
  );

  const errorElement = <ErrorElement error={fieldError} fieldId={name} />;

  return (
    <div className={containerClassNames ? containerClassNames : 'col-span-full'}>
      {inverseLabel && inputElement}
      {labelElement}
      {!inverseLabel && inputElement}
      {errorElement}
    </div>
  );
};

export default FormFieldInput;
