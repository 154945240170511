import React, { useState } from 'react';
import { TermFacet } from '@Types/result/TermFacet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { Category } from '@Types/product/Category';
import { Facet } from '@Types/result/Facet';
import BellIcon from '../../../icons/bell';
import BikeAlarmOverview from '../overview';
import Offcanvas from '../../products/product-details/offcanvas';

export interface Props {
  btnLabel: string;
  headline: string;
  subline: string;
  searchLabel: string;
  emailLabel: string;
  disclaimer: string;
  newsletterLabel: string;
  overviewBtnLabel: string;
  activeTermFacets: TermFacet[];
  activeRangeFacets: RangeFacet[];
  categoryTitle: string;
  category: Category;
  visibleFilters?: string[];
  facets: Facet[];
}

const BikeAlarmButton: React.FC<Props> = ({
  headline,
  subline,
  searchLabel,
  emailLabel,
  disclaimer,
  newsletterLabel,
  btnLabel,
  overviewBtnLabel,
  activeTermFacets,
  activeRangeFacets,
  categoryTitle,
  category,
  visibleFilters,
  facets,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const overviewData = {
    headline: headline,
    subline: subline,
    searchLabel: searchLabel,
    emailLabel: emailLabel,
    disclaimer: disclaimer,
    newsletterLabel: newsletterLabel,
    btnLabel: overviewBtnLabel,
    categoryTitle: categoryTitle,
    category: category,
  };

  const addScrollLock = () => {
    document.body.style.overflow = 'hidden';
  };

  const removeScrollLock = () => {
    document.body.style.overflow = '';
  };

  const repositionTSBadge = () => {
    const tsBadge: HTMLDivElement = document.body.querySelector('div[id^="minimized-trustbadge-"]');

    if (tsBadge) {
      tsBadge.setAttribute(
        'style',
        `transition: all 0.4s ease-in-out ${isOpen ? '0.23s' : ''} !important; opacity: ${
          isOpen ? '1' : '0'
        } !important; visibility: ${isOpen ? 'visible' : 'hidden'} !important;`,
      );
    }
  };

  function openOffcanvas() {
    repositionTSBadge();
    setIsOpen(true);
    addScrollLock();
  }

  function closeOffcanvas() {
    repositionTSBadge();
    setIsOpen(false);
    removeScrollLock();
  }
  const offcanvasBody: React.JSX.Element = (
    <BikeAlarmOverview
      {...overviewData}
      filteredTermFacets={activeTermFacets}
      filteredRangeFacets={activeRangeFacets}
      categoryTitle={categoryTitle}
      category={category}
      visibleFilters={visibleFilters.map((filter) => filter.toLowerCase())}
      facets={facets}
    />
  );

  return (
    <>
      <div className="fixed bottom-4 left-0 right-0 z-50 mb-4 flex w-full justify-center lg:relative lg:top-0">
        <button
          className="flex items-center justify-center gap-2 rounded-md bg-blue-600 px-4 py-3 lg:w-full"
          onClick={openOffcanvas}
        >
          <BellIcon className="size-6 text-primary-100" aria-hidden="true" />
          <div className="text-sm text-font-light">{btnLabel}</div>
        </button>
      </div>

      <Offcanvas
        body={offcanvasBody}
        show={isOpen}
        closeOverlay={() => closeOffcanvas()}
        mobileWidth="w-full"
        bgColor="white"
      />
    </>
  );
};

export default BikeAlarmButton;
